<template>
    <div>
        <v-card flat>
            <v-card-title>
                <span class="text-h5">{{ title }}</span>
                <v-spacer></v-spacer>
                <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Buscar"
                    single-line
                    hide-details
                ></v-text-field>
            </v-card-title>
            <v-card-text>
                <v-container>
                  
                    <v-data-table
                        :headers="headers"
                        :items="itemsDetails"
                        :items-per-page=-1
                        class="elevation-1 custom-font"
                        :loading="load"
                        loading-text="Cargando... Espere por favor"
                        :search="search"
                        v-model="selected"
                        :show-select="autorize"
                    >
                        <template v-slot:item.quantity="{ item }">
                            <v-chip v-if="item.color" :color="item.color" text-color="white">{{ item.quantity }}</v-chip>
                            <span v-else>{{ item.quantity }}</span>
                        </template>
                    </v-data-table>

                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-alert shaped dense dark type="info" v-if="info !== ''">
                    {{ info }}
                </v-alert>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="generatePurchaseOrder" v-if="user.level !== 'user' && generateOrderPurchase">
                    Generar Orden de Compra
                </v-btn>
                <v-btn color="blue darken-1" text @click="processPurchaseOrder" v-if="user.level !== 'user' && generatePurchase">
                    Finalizar Orden de Compra
                </v-btn>
                <v-btn color="blue darken-1" text @click="generateDeliveries" v-if="user.level !== 'user' && deliver">
                    Generar Entregas
                </v-btn>
                <v-btn color="blue darken-1" text @click="autorizar" v-if="user.level !== 'user' && autorize">
                    Autorizar
                </v-btn>
                <v-btn color="blue darken-1" text @click="comments = true" v-if="user.level !== 'user' && autorize">
                    Rechazar
                </v-btn>
                <v-btn color="blue darken-1" text @click="calculate" v-if="user.level !== 'user' && calculateStock && purchase">
                    Procesar Envio
                </v-btn>
                <v-btn color="blue darken-1" text @click="calculatePdf">
                    Imprimir
                </v-btn>
                <v-btn color="blue darken-1" text @click="cancelStock">
                    Cerrar
                </v-btn>

            </v-card-actions>
        </v-card>
        <div>
            <ReportDataPdfBasic
            v-if="dataReady"
                :name="titlePdf"
                :title="titlePdf"
                :elementsPerPag=20
                :headerAll=false
                :headers="headersToPdf"
                :items="itemsDetailsToPdf"
                :inf="hoy"
                ref="pdfComponent"
            />
        </div>
        <div>
            <v-dialog v-model="comments" max-width="1000px" :key="keyView" >
                <comments v-on="{ closeModal, save }" />
            </v-dialog>
        </div>
    </div>
</template>
<script>
import { getRequestById, getFilteredProducts, updateRequest, getStockByProduct, updateRequestItem } from '../dataApi'
import UserProfile from "../data";
import ReportDataPdfBasic from "./report-data-basic-pdf.vue";
import comments from "./comments.vue"
import { format } from "@formkit/tempo"
import swal from "sweetalert";
export default {
    components: {
        ReportDataPdfBasic,
        comments
    },
    props: {
        request: {
            type: Number,
            required: true
        },
        quoteRequest: {
            type: Boolean,
            required: false
        },
        purchaseOrder: {
            type: Boolean,
            required: false
        },
        purchase: {
            type: Boolean,
            required: false
        }
    },
    name: 'requestView',
    data: () => ({
        user: UserProfile.getUserData(),
        itemsDetails: [],
        dataReady: false,
        //headers: [],
        info: "",
        status: "",
        ref: "",
        load: true,
        search: "",
        comments: false,
        keyView: 0,
        selected: [],
        headersToPdf: [],
        itemsDetailsToPdf: [],
        titlePdf: ""
    }),

    methods: {
        async createdValuesItems(){
            const { data } = await getRequestById(this.request)
            this.itemsDetails = data.items
            this.load = false
            this.info = `Solicitante: ${data.user.fullName} en ${format(data.requestDate,'DD-MM-YYYY H:mm:ss', 'es')}`
            this.status = data.status
            this.ref = data.ref
        },

        cancelStock(){
            this.$emit("closeModal")
        },

        async calculatePdf(){
            this.dataReady = true
            this.headersToPdf = [
                    { text: 'Colaborador', value: 'employee.firstName' },
                    { text: 'Apellido', value: 'employee.fatherName' },
                    { text: 'Centro de Costo', value: 'employee.costCenter' },
                    { text: 'Posicion', value: 'employee.position' },
                    { text: 'Producto', value: 'product.name' },
                    { text: 'Cantidad', value: 'quantity' },
                    { text: 'Zona', value: 'product.zone' },
                ]
            if((!this.quoteRequest && !this.deliver) &&  this.purchaseOrder){
                const itemsBySupplier = this.classifyItemsBySupplier(this.itemsDetails)
                for await (const items of itemsBySupplier) {
                    await new Promise((resolve) => {
                        this.titlePdf = this.calculateTitlePdfToProvider(items)
                        this.itemsDetailsToPdf = items;
                        console.log({items})
                        setTimeout(() => {
                            this.$refs.pdfComponent.generatePdf();
                            resolve();
                        }, 1000);
                    });
                }
            } else if(this.deliver){
                this.headersToPdf = [
                    { text: 'Nombre', value: 'employee.firstName' },
                    { text: 'Apellido', value: 'employee.fatherName' },
                    { text: 'Cantidad', value: 'quantity' },
                    { text: 'Producto', value: 'product.description' },
                    { text: 'Size', value: 'product.size' },
                ]
                const itemsByEmployee = this.classifyItemsByEmployee(this.itemsDetails)
                for await (const items of itemsByEmployee) {
                    await new Promise((resolve) => {
                        this.itemsDetailsToPdf = items.items;
                        this.titlePdf = `Entregas: ${this.ref}`
                        setTimeout(() => {
                            this.$refs.pdfComponent.generatePdf()
                            resolve();
                        }, 1000)
                    })
                }
            } else {
                this.itemsDetailsToPdf = this.itemsDetails
                this.titlePdf = this.calculateTitlePdfToProvider()
                setTimeout(() => {
                    this.$refs.pdfComponent.generatePdf()
                }, 500)
            }
        },

        calculateTitlePdfToProvider(items = null){
            if(!items) return `Orden de Compra: ${this.ref}`;
            const supplier = items[0].product.supplier;
            return this.purchaseOrder ? `Orden de Compra: ${this.ref} - ${supplier.name}` : `Solicitud de Cotizacion: ${this.ref} - ${supplier.name}`
        },

        save(){
            console.log("save")
            this.keyView++
            this.comments = false
        },

        closeModal(){
            console.log("closeModal")
            this.keyView++
            this.comments = false
        },
        autorizar(){
            const cantSelected = this.selected.length
            const cantItems = this.itemsDetails.length
            if(cantSelected === 0){
                swal({text: "Debe seleccionar al menos un item para Autorizar", icon: "error"})
                return
            }
            if(cantSelected !== cantItems){
                swal({text: "Los items no seleccionados seran rechazados, ¿Desea continuar?", icon: "warning", buttons: ["Cancelar", "Continuar"],})
                return
            }
            swal({text: "Se procede a autorizar los items seleccionados, ¿Desea continuar?", icon: "info", buttons: ["Cancelar", "Continuar"],})
            .then(async() => {      
                
                for await (const item of this.itemsDetails) {
                    const sizeRef = item.category.sizeRef
                
                    const employeeKey = this.getKeyCaseInsensitive(item.employee, sizeRef);
                    const sizeCalculated = item.employee[employeeKey]
                
                    if(sizeCalculated !== null){
                
                        let productsFiltered = await getFilteredProducts({
                            size: sizeCalculated,
                            categoryId: item.category.id,
                            sex: item.employee.gender
                        })
                        if(productsFiltered.length === 0){
                            productsFiltered = await getFilteredProducts({
                                size: sizeCalculated,
                                categoryId: item.category.id,
                                sex: 'U'
                            })
                        }
                        if(productsFiltered.length > 0){
                            item.product = productsFiltered[0];
                            item.product.description = productsFiltered[0].text
                            item.productId = productsFiltered[0].value
                        }
                    }
                }
                
                const updateRequestDto = {
                    id: this.request,
                    ref: this.ref,
                    status: 'AUTORIZADO',
                    quoteRequest: true,
                    purchaseOrder: false,
                    purchase: false,
                    items: this.itemsDetails.map(item => ({
                        id: item.id,
                        quantity: item.quantity,
                        productId: item.productId,
                        
                        categoryId: item.category.id,
                        
                        finalEmployeeId: item.employee.id,
                    }))
                }

                await updateRequestItem(updateRequestDto)
                this.cancelStock()
            })
        },

        async generatePurchaseOrder(){
            swal({text: "Se procede a generar la Orden de Compra, ¿Desea continuar?", icon: "info", buttons: ["Cancelar", "Continuar"],})
            .then(async() => {
                await updateRequest({
                    id: this.request,
                    purchaseOrder: true
                })
                this.cancelStock()
            })
        },

        async processPurchaseOrder(){
            swal({text: "Se procede a finalizar exitosamente la Orden de Compra, ¿Desea continuar?", icon: "info", buttons: ["Cancelar", "Continuar"],})
            .then(async() => {
                await updateRequest({
                    id: this.request,
                    purchase: true,
                    status: 'RECIBIDO'
                })
                this.cancelStock()
            })
        },

        async generateDeliveries(){
            swal({text: "Se procede a finalizar exitosamente la Entrega, ¿Desea continuar?", icon: "info", buttons: ["Cancelar", "Continuar"],})
            .then(async() => {
                await updateRequest({
                    id: this.request,
                    status: 'ENTREGADO'
                })
                this.cancelStock()
            })
        },

        async calculate(){
            this.load = true
            const requestInventory = []
            this.itemsDetails.map(item => {
                const exist = requestInventory.findIndex(product => product.productId === item.productId)
                if(exist !== -1){
                    requestInventory[exist].quantity += item.quantity
                }else{
                    requestInventory.push({
                        productId: item.productId,
                        quantity: item.quantity
                    })
                }
            })
            
            const { data } = await getStockByProduct({productQuantities: requestInventory})
            for await (const stock of data.productQuantities) {
                this.itemsDetails.forEach(item => {
                    if(!item.proccess && stock.stock > 0 && item.productId === stock.productId){
                        item.proccess = true
                        if(stock.stock >= item.quantity){
                            item.stock = item.quantity;
                            stock.stock -= item.quantity
                            item.color = 'green'
                        }
                    }
                })
            }
            let redFlag = false
            this.itemsDetails.forEach(item => {
                if(!item.proccess){
                    item.color = 'red'
                    item.stock = 0
                    redFlag = true
                }
            })
            if(redFlag){
                swal({text: "Existen productos que no tienen stock suficiente, ¿Desea continuar?", icon: "warning", buttons: ["Cancelar", "Continuar"],})
            }
            this.load = false
        },

        getKeyCaseInsensitive(obj, key) {
            if(!key) return null
            return Object.keys(obj).find(k => k.toLowerCase() === key.toLowerCase());
        },

        classifyItemsBySupplier(items) {
             const groupedBySupplier = items.reduce((acc, item) => {
                const supplierId = item.product.supplier.id;

                if (!acc[supplierId]) {
                    acc[supplierId] = {
                        supplier: item.product.supplier,
                        items: []
                    };
                }

                acc[supplierId].items.push(item);
                return acc;
            }, {});
            
            return Object.values(groupedBySupplier).map(group => group.items);
        },

        classifyItemsByEmployee(items){
            const groupedByEmployee = items.reduce((acc, item) => {
                const employeeId = item.employee.id;
                if(!acc[employeeId]){
                    acc[employeeId] = {
                        employee: item.employee,
                        items: []
                    };
                }
                acc[employeeId].items.push(item);
                return acc;
            }, {});
            return Object.values(groupedByEmployee);
        },
    },

    mounted() {
        this.createdValuesItems();
    },

    computed: {
        hoy(){
            const date = new Date();
            return format(date,'DD-MM-YYYY H:mm:ss', 'es')
        },
        title(){
            if(this.quoteRequest){
                return `Solicitud de Cotizacion: ${this.ref}`
            }
            if(this.purchaseOrder){
                return `Orden de Compra: ${this.ref}`
            }
            return `Ref: ${this.ref} - ${this.status}`
        },
        headers(){
            const headers = [
            { text: 'Nombre', value: 'employee.firstName' },
            { text: 'Apellido', value: 'employee.fatherName' },
            { text: 'Cantidad', value: 'quantity' },
            { text: 'Observacion', value: 'product.description' },
            ]
            if(this.quoteRequest || this.purchaseOrder){
                headers.splice(3, 0, { text: 'Producto', value: 'product.name' })
                return headers
            } else {
                headers.splice(3, 0, { text: 'Producto', value: 'category.name' })
                return headers
            }
        },
        autorize(){
            return this.status === 'SOLICITADO'
        },
        calculateStock(){
            return this.status === 'AUTORIZADO' && (!this.quoteRequest && !this.purchaseOrder)
        },
        generateOrderPurchase(){
            console.log(this.status, this.quoteRequest, this.purchaseOrder)
            return this.status === 'AUTORIZADO' && this.quoteRequest && !this.purchaseOrder
        },
        generatePurchase(){
            return this.status === 'AUTORIZADO' && this.purchaseOrder && !this.purchase
        },
        deliver(){
            const res = this.status === 'RECIBIDO' && this.purchase
            console.log(res)
            return res
        },
    }
}
</script>